import styled from 'styled-components';

export const ModalContent = styled.form`
  padding: 32px;
`;

export const ButtonGroup = styled.div`
  margin-top: 32px;

  display: flex;

  button + button {
    margin-right: 8px;
  }
`;
