import { ModalCourses } from 'pages/Courses/containers/ModalCourses';
import { TableCourses } from 'pages/Courses/containers/TableCourses';
import { useCourses } from 'pages/Courses/context';
import { MdAdd } from 'react-icons/md';
import * as S from './styles';

export const CoursesScreen = (): JSX.Element => {
  document.title = 'Cursos | Instituto Discens';
  const { isOpenModal, setIsOpenModal, setEditId } = useCourses();

  return (
    <S.Container>
      <S.Header>
        <S.Title>Cursos disponíveis</S.Title>
        <S.Button
          onClick={() => {
            setEditId(null);
            setIsOpenModal(true);
          }}
        >
          <MdAdd size={20} /> CADASTRAR NOVO CURSO
        </S.Button>
      </S.Header>
      <S.Content>
        <TableCourses />
      </S.Content>
      {isOpenModal && (
        <ModalCourses isOpen={isOpenModal} setIsOpen={setIsOpenModal} />
      )}
    </S.Container>
  );
};
