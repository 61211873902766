import { BrowserRouter } from 'react-router-dom';
import { RoutesApp } from 'routes/Routes';
import GlobalStyles from 'styles/global';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { AuthContextProvider } from 'store/Auth';

const App = () => {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <GlobalStyles />
        <RoutesApp />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </AuthContextProvider>
    </BrowserRouter>
  );
};

export default App;
