import { ModalContacts } from 'pages/Contacts/containers/ModalContacts';
import { TableContacts } from 'pages/Contacts/containers/TableContacts';
import { useContacts } from 'pages/Contacts/context';
import * as S from './styles';

export const ContactsScreen = (): JSX.Element => {
  document.title = 'Contatos | Instituto Discens';

  const { isOpenModal, setIsOpenModal } = useContacts();

  return (
    <S.Container>
      <S.Header>
        <S.Title>Contatos</S.Title>
      </S.Header>
      <S.Content>
        <TableContacts />
      </S.Content>
      {isOpenModal && (
        <ModalContacts isOpen={isOpenModal} setIsOpen={setIsOpenModal} />
      )}
    </S.Container>
  );
};
