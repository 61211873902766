import { sg } from 'styles';

export enum ButtonVariants {
  inline = 'inline',
  outlined = 'outlined',
  danger = 'danger',
}

export type ButtonVariantData = {
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  hoverBackgroundColor?: string;
  hoverColor?: string;
};

export const buttonVariants: { [key in ButtonVariants]: ButtonVariantData } = {
  inline: {
    color: sg.colors.white,
    backgroundColor: sg.colors.primary,
    hoverBackgroundColor: sg.colors.blue5,
    hoverColor: sg.colors.white,
  },
  outlined: {
    color: sg.colors.primary,
    backgroundColor: 'transparent',
    borderColor: sg.colors.primary,
    hoverBackgroundColor: sg.colors.primary,
    hoverColor: sg.colors.white,
  },
  danger: {
    color: sg.colors.red2,
    backgroundColor: 'transparent',
    borderColor: sg.colors.red2,
    hoverBackgroundColor: sg.colors.red2,
    hoverColor: sg.colors.white,
  },
};
