import React, { useState, createContext, useContext } from 'react';
import { toast } from 'react-toastify';
import { getContacts, updateStatus } from 'services/api/contact';
import { IContactProps } from 'types/ContactTypes';

type IContactsProps = {
  children: React.ReactNode;
};

export type IContactsContextProps = {
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  perPage: number;
  totalRegisters: number;
  handleGetContacts: ({ page }: { page?: number }) => void;
  contacts: IContactProps[];
  idContact: number | null;
  setIdContact: React.Dispatch<React.SetStateAction<number | null>>;
  handleStatusContact: ({ status }: { status: boolean }) => void;
};

export const ContactsContext = createContext<IContactsContextProps>(
  {} as IContactsContextProps
);

export const ContactsProvider = ({ children }: IContactsProps): JSX.Element => {
  const perPage = 8;

  const [page, setPage] = useState(1);
  const [totalRegisters, setTotalRegisters] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [contacts, setContacts] = useState<IContactProps[]>([]);

  const [idContact, setIdContact] = useState<number | null>(null);

  const handleGetContacts = async ({ page = 1 }: { page?: number }) => {
    try {
      const { data, total } = await getContacts({
        page,
        perPage,
      });

      setContacts(data);
      setTotalRegisters(total);
    } catch (error) {
      toast.error('Erro ao carregar os contatos');
    }
  };

  const handleStatusContact = async ({ status }: { status: boolean }) => {
    try {
      const { status: statusCode } = await updateStatus({
        id: idContact as number,
        status,
      });

      if (statusCode === 200) {
        setIsOpenModal(false);
        handleGetContacts({ page });
        toast.success('Status atualizado com sucesso');
      }
    } catch (error) {
      toast.error('Erro ao carregar os contatos');
    }
  };

  return (
    <ContactsContext.Provider
      value={{
        isOpenModal,
        setIsOpenModal,
        totalRegisters,
        page,
        perPage,
        setPage,
        handleGetContacts,
        contacts,
        idContact,
        setIdContact,
        handleStatusContact,
      }}
    >
      {children}
    </ContactsContext.Provider>
  );
};

export const useContacts = () => {
  const context = useContext(ContactsContext);

  if (!context) {
    throw new Error('You just can access this context inside a provider');
  }

  return context;
};
