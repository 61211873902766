import { breakpoints, media } from './breakpoints';
import { colors } from './colors';

export const sg = Object.freeze({
  media,
  colors,
  breakpoints,
  fontFamily: {
    primary: 'Fira Sans',
    secondary: 'Montserrat',
  },
  fontSize: {
    xsmall: '12px',
    small: '14px',
    default: '16px',
    medium: '18px',
    large: '22px',
    xlarge: '24px',
    xxlarge: '26px',
    titleSmall: '30px',
    titleMedium: '40px',
    titleLarge: '48px',
    titleXLarge: '68px',
  },
});
