import { api } from 'services/api';
import {
  ICreateCourse,
  IDeleteCourse,
  IEditCourse,
  IGetCourse,
  IGetCourses,
} from 'services/api/course';

export const getCourses: IGetCourses = async (params) => {
  const { data } = await api.get('/courses', {
    params,
  });

  return {
    page: data.page,
    perPage: data.perPage,
    total: data.total,
    lastPage: data.lastPage,
    data: data.data,
  };
};

export const createCourse: ICreateCourse = async (params) => {
  const { status } = await api.post('/course', params);

  return {
    status,
  };
};

export const editCourse: IEditCourse = async (params) => {
  const { status } = await api.put(`/course/${params.id}`, params);

  return {
    status,
  };
};

export const getCourse: IGetCourse = async (params) => {
  const { data } = await api.get(`/course/${params.id}`);

  return data;
};

export const deleteCourse: IDeleteCourse = async (params) => {
  const { status } = await api.delete(`/courses/${params.id}`);

  return { status };
};
