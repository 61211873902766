import React from 'react';

interface IconProps {
  size: number;
  color: string;
}

export const IconDownload = ({ size, color }: IconProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M5.767 7.733L7.9 9.867l2.133-2.134M7.9 1.333v8.475"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M14.667 8.15c0 3.683-2.5 6.667-6.667 6.667S1.333 11.833 1.333 8.15"
      />
    </svg>
  );
};

export default IconDownload;
