import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { sg } from 'styles';

export const Container = styled.div`
  display: flex;

  width: 100%;
  height: 100vh;
`;

export const Sidebar = styled.nav`
  background-color: ${sg.colors.white};
  width: 326px;

  display: flex;
  flex-direction: column;
`;

export const Content = styled.main`
  flex: 1;

  background: ${sg.colors.background};

  overflow-y: auto;
`;

export const Logo = styled.img`
  width: 100%;
  max-width: 180px;

  align-self: center;
  margin: 48px 24px 0px;
`;

export const Title = styled.p`
  font-size: ${sg.fontSize.xsmall};
  color: ${sg.colors.textSecondary};
  font-weight: 500;
  letter-spacing: 1px;

  margin-bottom: 16px;

  margin-left: 24px;
`;

export const Items = styled.div`
  margin-top: 48px;
`;

export const Link = styled(NavLink)`
  display: flex;
  align-items: center;
  cursor: pointer;

  padding: 17px 0px 17px 24px;
  text-decoration: none;
  color: ${sg.colors.textSecondary};

  svg {
    margin-right: 16px;
    font-size: 0px;
  }

  &.active {
    background: rgba(0, 124, 194, 0.07);
    color: ${sg.colors.primary};
    font-weight: 600;

    svg {
      path {
        stroke: ${sg.colors.primary};
      }
    }
  }
`;
