import { Button, Input } from 'components';

import logoWhite from 'assets/logo-white.svg';
import logoPrimary from 'assets/logo-primary.svg';

import { useState } from 'react';
import { useAuth } from 'store/Auth';
import { toast } from 'react-toastify';
import * as S from './styles';

export const LoginScreen = (): JSX.Element => {
  document.title = 'Instituto Discens';

  const { signIn } = useAuth();

  const [fields, setFields] = useState({
    email: '',
    password: '',
  });

  const [fieldsError, setFieldsError] = useState({
    emailError: false,
    passwordError: false,
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      if (!fields.email) {
        setFieldsError({
          ...fieldsError,
          emailError: true,
        });
        return;
      }

      if (!fields.password) {
        setFieldsError({
          ...fieldsError,
          passwordError: true,
        });
        return;
      }

      setFieldsError({
        passwordError: false,
        emailError: false,
      });

      await signIn({
        email: fields.email,
        password: fields.password,
      });
    } catch (error) {
      toast.error('Algo de errado aconteceu, tente novamente.');
    }
  };

  return (
    <S.Container>
      <S.ContentLeft>
        <S.Logo src={logoPrimary} alt="Logo" />
        <S.Title>Login</S.Title>
        <S.Description>
          Informe os dados abaixo para realizar o login na plataforma.
        </S.Description>
        <S.Form onSubmit={handleSubmit}>
          <Input
            placeholder="Email"
            value={fields.email}
            onChange={(e) => setFields({ ...fields, email: e.target.value })}
            error={fieldsError.emailError}
          />
          <Input
            placeholder="Senha"
            type="password"
            value={fields.password}
            onChange={(e) => setFields({ ...fields, password: e.target.value })}
            error={fieldsError.passwordError}
          />
          <Button label="ACESSAR PLATAFORMA" type="submit" />
        </S.Form>
      </S.ContentLeft>
      <S.ContentRight>
        <img src={logoWhite} alt="Instituto Discens" />
      </S.ContentRight>
    </S.Container>
  );
};
