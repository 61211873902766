import { sg } from 'styles';
import styled from 'styled-components';
import { ButtonVariantData } from 'components/Button/theme';

export const Container = styled.button<{
  variant: ButtonVariantData;
  width: string;
}>`
  cursor: pointer;
  width: ${({ width }) => width};

  background: ${({ variant }) => variant.backgroundColor};

  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-size: ${sg.fontSize.small};
  font-family: ${sg.fontFamily.primary};
  font-weight: 400;
  color: ${({ variant }) => variant.color};

  ${({ variant }) =>
    variant.borderColor && `border: 1px solid ${variant.borderColor}`};

  padding: 18px 15px;
  border-radius: 5px;

  transition: all 0.2s ease-in-out;

  &:hover:not([disabled]) {
    background: ${({ variant }) => variant.hoverBackgroundColor};
    color: ${({ variant }) => variant.hoverColor};
  }

  & + & {
    margin-left: 8px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding-right: 10px;
`;
