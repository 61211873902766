import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { sg } from 'styles';

import moment from 'moment';
import 'moment/locale/pt-br';

import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import { BiEdit } from 'react-icons/bi';
import { useEffect } from 'react';
import { useCourses } from 'pages/Courses/context';
import * as S from './styles';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: sg.colors.background,
    color: sg.colors.grey6,
    fontFamily: sg.fontFamily.primary,
    fontSize: sg.fontSize.default,
    height: '66px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: sg.fontFamily.primary,
    fontSize: sg.fontSize.small,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  'td, tr': {
    color: sg.colors.textPrimary,
    fontFamily: sg.fontFamily.primary,
    fontSize: sg.fontSize.small,
    fontWeight: 500,
    height: '66px',
    borderColor: sg.colors.grey3,
  },

  'td.inactive': {
    color: sg.colors.textSecondary,
  },
}));

export const TableCourses = (): JSX.Element => {
  const {
    page,
    perPage,
    setPage,
    totalRegisters,
    setIsOpenModal,
    handleGetCourses,
    courses,
    setEditId,
    handleEditStatus,
  } = useCourses();

  useEffect(() => {
    handleGetCourses({ page });
  }, [page]);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">Nome</StyledTableCell>
            <StyledTableCell align="left">Data de envio</StyledTableCell>
            <StyledTableCell align="left">Enviado por</StyledTableCell>
            <StyledTableCell align="left">Ações</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {courses.map((course) => (
            <StyledTableRow key={course.id}>
              <StyledTableCell
                align="left"
                className={course.status ? '' : 'inactive'}
              >
                {course.name}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                className={course.status ? '' : 'inactive'}
              >
                {moment(course.created_at).format('LLL')}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                className={course.status ? '' : 'inactive'}
              >
                {course.user.firstName} {course.user.lastName}
              </StyledTableCell>
              <StyledTableCell align="left">
                <S.ButtonIcon
                  onClick={() => {
                    setIsOpenModal(true);
                    setEditId(course.id);
                  }}
                >
                  <BiEdit color={sg.colors.quaternary} size={22} />
                </S.ButtonIcon>
                <S.ButtonIcon
                  onClick={() => handleEditStatus(course.id, !course.status)}
                >
                  {course.status ? (
                    <RiEyeLine color={sg.colors.textSecondary} size={22} />
                  ) : (
                    <RiEyeOffLine color={sg.colors.textSecondary} size={22} />
                  )}
                </S.ButtonIcon>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={Number(totalRegisters)}
        page={page - 1}
        onPageChange={(_, newPage) => {
          setPage(newPage + 1);
        }}
        rowsPerPage={perPage}
        rowsPerPageOptions={[]}
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}-${to} de ${count}`;
        }}
      />
    </>
  );
};
