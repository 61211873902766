import {
  DownloadsScreen,
  CoursesScreen,
  LayoutScreen,
  LoginScreen,
  ContactsScreen,
} from 'pages';
import { ContactsProvider } from 'pages/Contacts/context';
import { CoursesProvider } from 'pages/Courses/context';
import { DownloadsProvider } from 'pages/Downloads/context';
import { Route, Routes } from 'react-router-dom';
import { RequireAuth } from 'routes/Route';

export const RoutesApp = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<LoginScreen />} />

      <Route element={<LayoutScreen />}>
        <Route
          path="/downloads"
          element={
            <RequireAuth>
              <DownloadsProvider>
                <DownloadsScreen />
              </DownloadsProvider>
            </RequireAuth>
          }
        />
        <Route
          path="/cursos"
          element={
            <RequireAuth>
              <CoursesProvider>
                <CoursesScreen />
              </CoursesProvider>
            </RequireAuth>
          }
        />
        <Route
          path="/contatos"
          element={
            <RequireAuth>
              <ContactsProvider>
                <ContactsScreen />
              </ContactsProvider>
            </RequireAuth>
          }
        />
      </Route>
    </Routes>
  );
};
