import { Outlet } from 'react-router-dom';
import logoPrimary from 'assets/logo-primary.svg';
import { IconContact, IconDownload, IconNotes } from 'assets/icons';
import { sg } from 'styles';
import { MdOutlineLogout } from 'react-icons/md';
import { useAuth } from 'store/Auth';
import * as S from './styles';

export const LayoutScreen = (): JSX.Element => {
  const { signOut } = useAuth();

  return (
    <S.Container>
      <S.Sidebar>
        <S.Logo src={logoPrimary} alt="Logo" />

        <S.Items>
          <S.Title>MENU PRINCIPAL</S.Title>
          <S.Link
            to="/downloads"
            className={(navData) => (navData.isActive ? 'active' : '')}
          >
            <IconDownload size={20} color={sg.colors.textSecondary} />
            Downloads
          </S.Link>
          <S.Link
            to="/cursos"
            className={(navData) => (navData.isActive ? 'active' : '')}
          >
            <IconNotes size={20} color={sg.colors.textSecondary} />
            Cursos
          </S.Link>
          <S.Link
            to="/contatos"
            className={(navData) => (navData.isActive ? 'active' : '')}
          >
            <IconContact size={20} color={sg.colors.textSecondary} />
            Contatos
          </S.Link>
          <S.Link
            to=""
            onClick={() => {
              signOut();
            }}
            className={(navData) => (navData.isActive ? 'active' : '')}
          >
            <MdOutlineLogout size={22} color={sg.colors.textSecondary} />
            Sair
          </S.Link>
        </S.Items>
      </S.Sidebar>
      <S.Content>
        <Outlet />
      </S.Content>
    </S.Container>
  );
};
