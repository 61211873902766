/* eslint-disable prefer-destructuring */
import {
  ICreateCourseProps,
  ICreateCourseResponseProps,
  IDeleteCourseProps,
  IEditCourseProps,
  IGetCourseProps,
  IGetCourseResponseProps,
  IGetCoursesProps,
  IGetCoursesResponseProps,
} from 'types/CourseTypes';
import * as integration from './integration';

export const getCourses: IGetCourses = integration.getCourses;
export const createCourse: ICreateCourse = integration.createCourse;
export const editCourse: IEditCourse = integration.editCourse;
export const getCourse: IGetCourse = integration.getCourse;
export const deleteCourse: IDeleteCourse = integration.deleteCourse;

export type IGetCourses = (
  params: IGetCoursesProps
) => Promise<IGetCoursesResponseProps>;

export type ICreateCourse = (
  params: ICreateCourseProps
) => Promise<ICreateCourseResponseProps>;

export type IEditCourse = (
  params: IEditCourseProps
) => Promise<{ status: number }>;

export type IGetCourse = (
  params: IGetCourseProps
) => Promise<IGetCourseResponseProps>;

export type IDeleteCourse = (
  params: IDeleteCourseProps
) => Promise<{ status: number }>;
