import React from 'react';

interface IconProps {
  size: number;
  color: string;
}

export const IconContact = ({ size, color }: IconProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 17 19"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M4.333 9.333C1 9.333 1 10.825 1 12.667v.833c0 2.3 0 4.167 4.167 4.167h6.666C15.167 17.667 16 15.8 16 13.5v-.833c0-1.842 0-3.334-3.333-3.334-.834 0-1.067.175-1.5.5l-.85.9a2.499 2.499 0 01-3.642 0l-.842-.9c-.433-.325-.666-.5-1.5-.5zM14.333 9.333v-5C14.333 2.492 14.333 1 11 1H6C2.667 1 2.667 2.492 2.667 4.333v5"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M7.292 7.025h2.775M6.6 4.525h4.167"
      />
    </svg>
  );
};

export default IconContact;
