import { createGlobalStyle } from 'styled-components';
import { sg } from './style-guide';

const GlobalStyle = createGlobalStyle`
 * {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    outline: none;
    border: none;
    box-sizing: border-box;
    font-family: 'Fira Sans', sans-serif;
  }
  img {
    max-width: 100%;
    display: block;
  }
  html, body {
    scroll-behavior: smooth;
    background: ${sg.colors.white};
    color: ${sg.colors.textPrimary};
  }
  button {
    cursor: pointer;
  }

`;

export default GlobalStyle;
