import * as S from './styles';

export interface ILoadingProps {
  color: string;
  width: string;
  height: string;

  className?: string;
}

export const Loading = ({ color, width, height, className }: ILoadingProps) => {
  return (
    <S.Spinner
      className={className}
      aria-label="loading"
      viewBox="0 0 50 50"
      color={color}
      width={width}
      height={height}
    >
      <circle cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
    </S.Spinner>
  );
};
