import { ModalDownloads } from 'pages/Downloads/containers/ModalDownload';
import { TableDownloads } from 'pages/Downloads/containers/TableDownload';
import { useDownloads } from 'pages/Downloads/context';
import { MdAdd } from 'react-icons/md';

import * as S from './styles';

export const DownloadsScreen = (): JSX.Element => {
  document.title = 'Downloads | Instituto Discens';

  const { isOpenModal, setIsOpenModal, setEditId } = useDownloads();

  return (
    <S.Container>
      <S.Header>
        <S.Title>Downloads disponíveis</S.Title>
        <S.Button
          onClick={() => {
            setEditId(null);
            setIsOpenModal(true);
          }}
        >
          <MdAdd size={20} /> NOVO ARQUIVO
        </S.Button>
      </S.Header>
      <S.Content>
        <TableDownloads />
      </S.Content>
      {isOpenModal && (
        <ModalDownloads isOpen={isOpenModal} setIsOpen={setIsOpenModal} />
      )}
    </S.Container>
  );
};
