import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { sg } from 'styles';
import { BiEdit } from 'react-icons/bi';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';

import moment from 'moment';
import 'moment/locale/pt-br';

import { useEffect } from 'react';
import { useDownloads } from 'pages/Downloads/context';
import * as S from './styles';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: sg.colors.background,
    color: sg.colors.grey6,
    fontFamily: sg.fontFamily.primary,
    fontSize: sg.fontSize.default,
    height: '66px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: sg.fontFamily.primary,
    fontSize: sg.fontSize.small,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  'td, tr': {
    color: sg.colors.textPrimary,
    fontFamily: sg.fontFamily.primary,
    fontSize: sg.fontSize.small,
    fontWeight: 500,
    height: '66px',
    borderColor: sg.colors.grey3,
  },

  'td.inactive': {
    color: sg.colors.textSecondary,
  },
}));

export const TableDownloads = (): JSX.Element => {
  const {
    handleGetDownloads,
    downloads,
    page,
    perPage,
    setPage,
    totalRegisters,
    handleEditStatus,
    setEditId,
    setIsOpenModal,
  } = useDownloads();

  useEffect(() => {
    handleGetDownloads({ page });
  }, [page]);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">Nome</StyledTableCell>
            <StyledTableCell align="left">Data de Upload</StyledTableCell>
            <StyledTableCell align="left">Enviado por</StyledTableCell>
            <StyledTableCell align="left">Baixado por</StyledTableCell>
            <StyledTableCell align="center">Ações</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {downloads.map((download) => (
            <StyledTableRow key={download.id}>
              <StyledTableCell
                align="left"
                className={download.status ? '' : 'inactive'}
              >
                {download.name}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                className={download.status ? '' : 'inactive'}
              >
                {moment(download.created_at).format('LLL')}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                className={download.status ? '' : 'inactive'}
              >
                {download.user.firstName} {download.user.lastName}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                className={download.status ? '' : 'inactive'}
              >
                {download.count_downloads} pessoas
              </StyledTableCell>
              <StyledTableCell align="center">
                <S.ButtonIcon
                  onClick={() => {
                    setIsOpenModal(true);
                    setEditId(download.id);
                  }}
                >
                  <BiEdit color={sg.colors.quaternary} size={22} />
                </S.ButtonIcon>
                <S.ButtonIcon
                  onClick={() => {
                    handleEditStatus(download.id, !download.status);
                  }}
                >
                  {download.status ? (
                    <RiEyeLine color={sg.colors.textSecondary} size={22} />
                  ) : (
                    <RiEyeOffLine color={sg.colors.textSecondary} size={22} />
                  )}
                </S.ButtonIcon>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={Number(totalRegisters)}
        page={page - 1}
        onPageChange={(_, newPage) => {
          setPage(newPage + 1);
        }}
        rowsPerPage={perPage}
        rowsPerPageOptions={[]}
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}-${to} de ${count}`;
        }}
      />
    </>
  );
};
