import { IGetContact, IGetContacts, IUpdateStatus } from '.';
import { api } from '..';

export const getContacts: IGetContacts = async (params) => {
  const { data } = await api.get('/contacts', {
    params,
  });

  return {
    page: data.page,
    perPage: data.perPage,
    total: data.total,
    lastPage: data.lastPage,
    data: data.data,
  };
};

export const getContact: IGetContact = async (params) => {
  const { data } = await api.get(`/contact/${params.id}`);

  return data;
};

export const updateStatus: IUpdateStatus = async (params) => {
  const { status } = await api.put(`/contact/updateStatus/${params.id}`, {
    status: params.status,
  });

  return { status };
};
