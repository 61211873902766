import { useAuth } from 'store/Auth';
import { Navigate, useLocation } from 'react-router-dom';

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { user } = useAuth();
  const location = useLocation();

  if (!user?.email) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};
