import { buttonVariants, ButtonVariants } from 'components/Button/theme';
import React from 'react';

import { sg } from 'styles';
import { Loading } from '../Loading';

import * as S from './styles';

type IButtonProps = {
  label: string;
  loading?: boolean;
  onClick?: () => void;
  icon?: React.ReactElement;
  width?: string;
  type?: 'submit' | 'button';
  variant?: keyof typeof ButtonVariants;
  disabled?: boolean;
};

export const Button = ({
  label,
  loading,
  onClick,
  icon,
  variant = 'inline',
  width = '100%',
  type = 'button',
  disabled = false,
}: IButtonProps) => {
  const buttonVariant = buttonVariants[variant];

  return (
    <S.Container
      onClick={onClick}
      variant={buttonVariant}
      width={width}
      type={type}
      disabled={disabled}
    >
      {icon && <S.Icon>{icon}</S.Icon>}
      {!loading && label}
      {loading && (
        <Loading
          color={variant === 'inline' ? sg.colors.white : sg.colors.primary}
          height="22px"
          width="22px"
        />
      )}
    </S.Container>
  );
};
