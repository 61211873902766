import styled, { css } from 'styled-components';
import { sg } from 'styles';

export const Container = styled.textarea<{ error: boolean }>`
  resize: none;

  width: 100%;
  background: transparent;
  border: 1px solid ${sg.colors.textSecondary};
  border-radius: 5px;
  color: ${sg.colors.textPrimary};
  font-family: ${sg.fontFamily.primary};
  font-size: ${sg.fontSize.small};

  padding: 18px 14px;

  &::placeholder {
    color: ${sg.colors.textSecondary};
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${sg.colors.red2};
    `}
`;
