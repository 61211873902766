import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

import { useNavigate } from 'react-router-dom';

import { IAuthProps, IUserProps } from 'types/AuthTypes';
import { signIn as signInApi } from 'services/api/auth';
import { toast } from 'react-toastify';
import { api } from 'services/api';

type AuthContextType = {
  user: IUserProps | undefined;
  signIn: (params: IAuthProps) => Promise<void>;
  signOut: () => void;
};

type AuthContextProviderProps = {
  children: ReactNode;
};

type AuthState = {
  user: IUserProps;
  token: string;
};

export const AuthContext = createContext({} as AuthContextType);

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const navigate = useNavigate();
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('discens.token');
    const user = localStorage.getItem('discens.user');

    if (token && user) {
      // api.defaults.headers.authorization = `Bearer ${Token}`;
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signOut = useCallback(() => {
    localStorage.clear();
    setData({} as AuthState);
  }, []);

  async function signIn({ email, password }: IAuthProps) {
    try {
      const { token, user } = await signInApi({
        email,
        password,
      });

      localStorage.setItem('discens.user', JSON.stringify(user));
      localStorage.setItem('discens.token', token.token);

      api.defaults.headers.common.Authorization = `Bearer ${token.token}`;
      setData({ token: token.token, user });
      navigate('/downloads');
    } catch (err: any) {
      console.log(err.response.data[0].field);
      if (err.response.data[0].field === 'email') {
        toast.error('Email inválido');
      }

      if (err.response.data[0].field === 'password') {
        toast.error('Senha inválida');
      }

      if (err.response.data[0].field === 'email/senha') {
        toast.error('Email e senha inválidos');
      }
    }
  }

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        user: data.user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('You just can access this context inside a provider');
  }

  return context;
};
