import styled from 'styled-components';
import { sg } from 'styles';

export const Container = styled.div`
  background: ${sg.colors.white};
  border-radius: 12px;

  margin: 32px;
  padding: 32px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h3`
  color: ${sg.colors.grey5};
  font-size: ${sg.fontSize.xlarge};
`;

export const Button = styled.button`
  background: transparent;
  font-family: ${sg.fontFamily.primary};

  color: ${sg.colors.primary};
  font-weight: 500;

  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

export const Content = styled.div`
  margin-top: 32px;
`;
