export const colors = Object.freeze({
  white: '#ffffff',
  black: '#000000',
  background: '#E0E0E0',

  textPrimary: '#505D68',
  textSecondary: '#A1AEB7',

  primary: '#145DA0',
  secondary: '#007CC2',
  tertiary: '#294158',
  quaternary: '#56AEFF',

  blue5: '#114A7E',
  blue4: '#384F65',
  blue2: '#9EBED5',
  blue1: '#E7F6FC',

  grey7: '#828282',
  grey6: '#333333',
  grey5: '#4F4F4F',
  grey4: '#BDBDBD',
  grey3: '#E0E0E0',
  grey2: '#C2D1D9',
  grey1: '#EBF0F2',

  red2: '#C52330',
});
