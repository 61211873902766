import React, { useState, createContext, useContext } from 'react';
import { toast } from 'react-toastify';
import {
  createDownload,
  deleteDownload,
  editDownload,
  getDownloads,
} from 'services/api/download';
import {
  ICreateDownloadsProps,
  IDownloadResponseProps,
  IEditDownloadsProps,
} from 'types/DownloadTypes';

type IDownloadsProps = {
  children: React.ReactNode;
};

export type IDownloadsContextProps = {
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleGetDownloads: ({ page }: { page?: number }) => void;
  downloads: IDownloadResponseProps[];
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  perPage: number;
  totalRegisters: number;
  handleCreateDownload: (data: ICreateDownloadsProps) => void;
  handleEditStatus: (id: number, status: boolean) => void;
  setEditId: React.Dispatch<React.SetStateAction<number | null>>;
  editId: number | null;
  handleEditDownload: (params: Omit<IEditDownloadsProps, 'id'>) => void;
  handleDeleteDownload: (id: number) => void;
  loading: boolean;
  loadingDelete: boolean;
};

export const DownloadsContext = createContext<IDownloadsContextProps>(
  {} as IDownloadsContextProps
);

export const DownloadsProvider = ({
  children,
}: IDownloadsProps): JSX.Element => {
  const perPage = 8;

  const [editId, setEditId] = useState<number | null>(null);

  const [page, setPage] = useState(1);
  const [totalRegisters, setTotalRegisters] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [downloads, setDownloads] = useState<IDownloadResponseProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const handleGetDownloads = async ({ page = 1 }: { page?: number }) => {
    try {
      const { data, total } = await getDownloads({
        page,
        perPage,
      });

      setDownloads(data);
      setTotalRegisters(total);
    } catch (error) {
      toast.error('Erro ao carregar os downloads');
    }
  };

  const handleCreateDownload = async (download: ICreateDownloadsProps) => {
    try {
      setLoading(true);
      const { status } = await createDownload(download);

      if (status === 201) {
        toast.success('Download criado com sucesso');
        setIsOpenModal(false);
        handleGetDownloads({ page: 1 });
      }
    } catch (error) {
      toast.error('Erro ao criar o download');
    } finally {
      setLoading(false);
    }
  };

  const handleEditStatus = async (id: number, status: boolean) => {
    try {
      const { status: statusCode } = await editDownload({ id, status });

      if (statusCode === 201) {
        toast.success('Status atualizado com sucesso');
        handleGetDownloads({});
      }
    } catch (error) {
      toast.error('Erro ao alterar o status');
    }
  };

  const handleEditDownload = async (
    params: Omit<IEditDownloadsProps, 'id'>
  ) => {
    try {
      setLoading(true);

      const { status: statusCode } = await editDownload({
        id: editId as number,
        ...params,
      });

      if (statusCode === 201) {
        toast.success('Download atualizado com sucesso');
        setIsOpenModal(false);
        handleGetDownloads({});
      }
    } catch (error) {
      toast.error('Erro ao editar download');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteDownload = async (id: number) => {
    try {
      setLoadingDelete(true);
      const { status } = await deleteDownload({ id });

      if (status === 200) {
        toast.success('Download deletado com sucesso');
        handleGetDownloads({ page: 1 });
        setIsOpenModal(false);
      }
    } catch (error) {
      toast.error('Erro ao deletar download');
    } finally {
      setLoadingDelete(false);
    }
  };

  return (
    <DownloadsContext.Provider
      value={{
        isOpenModal,
        setIsOpenModal,
        handleGetDownloads,
        downloads,
        totalRegisters,
        page,
        perPage,
        setPage,
        handleCreateDownload,
        handleEditStatus,
        editId,
        setEditId,
        handleEditDownload,
        handleDeleteDownload,
        loading,
        loadingDelete,
      }}
    >
      {children}
    </DownloadsContext.Provider>
  );
};

export const useDownloads = () => {
  const context = useContext(DownloadsContext);

  if (!context) {
    throw new Error('You just can access this context inside a provider');
  }

  return context;
};
