/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useDropzone } from 'react-dropzone';

import * as S from './styles';

export interface IDropzoneProps {
  title?: string;
  description?: string;
  file?: File;
  setFile: (file: File) => void;
  error?: boolean;
}

export const Dropzone = ({
  title = 'Arraste e solte o arquivo aqui',
  description = 'ou se preferir...',
  setFile,
  error = false,
}: IDropzoneProps): JSX.Element => {
  const [errorInput, setErrorInput] = useState(false);

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: {
        'application/pdf': [],
        'image/*': [],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          [],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
        'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          [],
        'application/vnd.ms-excel': [],
        'application/vnd.ms-powerpoint': [],
        'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
          [],
      },
      maxFiles: 1,
    });
  const [fileNames, setFileNames] = useState('');

  useEffect(() => {
    acceptedFiles[0] && setFile(acceptedFiles[0]);
    setFileNames(acceptedFiles.map((file) => file.name).join(', '));

    if (
      fileRejections.length &&
      fileRejections[0].errors[0].code === 'too-many-files'
    ) {
      toast.error('Só é possível enviar um arquivo!');
    }

    if (
      fileRejections.length &&
      fileRejections[0].errors[0].code !== 'too-many-files'
    ) {
      toast.error('Tipo de arquivo não suportado');
    }
  }, [acceptedFiles, fileRejections]);

  useEffect(() => {
    setErrorInput(error);
    acceptedFiles[0] && setErrorInput(false);
  }, [acceptedFiles, error]);

  return (
    <S.Container error={errorInput}>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <S.Content>
          {fileNames ? (
            <S.Filename>{fileNames}</S.Filename>
          ) : (
            <>
              <h4>{title}</h4>
              <p>{description}</p>
            </>
          )}
          <button type="button">ESCOLHA UM ARQUIVO PARA FAZER UPLOAD</button>
        </S.Content>
      </div>
    </S.Container>
  );
};
