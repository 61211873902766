import styled from 'styled-components';
import { sg } from 'styles';

export const ModalContent = styled.form`
  padding: 32px;
`;

export const ButtonGroup = styled.div`
  margin-top: 32px;

  display: flex;

  button + button {
    margin-right: 8px;
  }
`;

export const Input = styled.div`
  position: relative;
  border: 1px solid ${sg.colors.primary};
  border-radius: 3px;
  padding: 17px 0px;

  & + div {
    margin-top: 16px;
  }
`;

export const Label = styled.label`
  position: absolute;
  top: -9px;
  background: ${sg.colors.white};
  padding: 0px 4px;
  margin: 0px 12px;
  color: ${sg.colors.primary};
  font-size: ${sg.fontSize.small};
`;

export const InputText = styled.p`
  color: ${sg.colors.grey5};
  margin: 0px 16px;
`;
