import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { sg } from 'styles';

import moment from 'moment';
import 'moment/locale/pt-br';

import { useEffect } from 'react';
import { MdOutlineInventory2 } from 'react-icons/md';
import { useContacts } from 'pages/Contacts/context';
import * as S from './styles';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: sg.colors.background,
    color: sg.colors.grey6,
    fontFamily: sg.fontFamily.primary,
    fontSize: sg.fontSize.default,
    height: '66px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: sg.fontFamily.primary,
    fontSize: sg.fontSize.small,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  'td, tr': {
    color: sg.colors.textPrimary,
    fontFamily: sg.fontFamily.primary,
    fontSize: sg.fontSize.small,
    fontWeight: 500,
    height: '66px',
    borderColor: sg.colors.grey3,
  },

  'td.inactive': {
    color: sg.colors.textSecondary,
  },
}));

export const TableContacts = (): JSX.Element => {
  const {
    page,
    perPage,
    setPage,
    totalRegisters,
    setIsOpenModal,
    handleGetContacts,
    contacts,
    setIdContact,
  } = useContacts();

  useEffect(() => {
    handleGetContacts({ page });
  }, [page]);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">Nome</StyledTableCell>
            <StyledTableCell align="left">Data de envio</StyledTableCell>
            <StyledTableCell align="left">Email</StyledTableCell>
            <StyledTableCell align="left">Status</StyledTableCell>
            <StyledTableCell align="left">Ações</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contacts.map((contact) => (
            <StyledTableRow key={contact.id}>
              <StyledTableCell
                align="left"
                className={contact.status ? 'inactive' : ''}
              >
                {contact.name}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                className={contact.status ? 'inactive' : ''}
              >
                {moment(contact.created_at).format('LLL')}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                className={contact.status ? 'inactive' : ''}
              >
                {contact.email}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                className={contact.status ? 'inactive' : ''}
              >
                {contact.status ? 'Realizado' : 'Não realizado'}
              </StyledTableCell>
              <StyledTableCell align="left">
                <S.ButtonIcon
                  onClick={() => {
                    setIsOpenModal(true);
                    setIdContact(contact.id);
                  }}
                >
                  <MdOutlineInventory2 color={sg.colors.quaternary} size={22} />{' '}
                  Ver detalhes
                </S.ButtonIcon>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={Number(totalRegisters)}
        page={page - 1}
        onPageChange={(_, newPage) => {
          setPage(newPage + 1);
        }}
        rowsPerPage={perPage}
        rowsPerPageOptions={[]}
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}-${to} de ${count}`;
        }}
      />
    </>
  );
};
