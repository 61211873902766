import { ISignIn } from '.';
import { api } from '..';

export const signIn: ISignIn = async (params) => {
  const { data } = await api.post('/auth', params);

  return {
    token: data.token,
    user: data.user,
  };
};
