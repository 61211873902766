import { Button, Input, Modal, Textarea } from 'components';
import { Dropzone } from 'components/Dropzone';
import { useDownloads } from 'pages/Downloads/context';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getDownload } from 'services/api/download';

import * as S from './styles';

interface IModalDownloadsProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const ModalDownloads = ({
  isOpen,
  setIsOpen,
}: IModalDownloadsProps): JSX.Element => {
  const {
    handleCreateDownload,
    editId,
    handleEditDownload,
    handleDeleteDownload,
    loading,
    loadingDelete,
  } = useDownloads();

  const [fields, setFields] = useState({
    name: '',
    description: '',
    file: {} as File,
    status: false,
  });

  const [errorsFields, setErrorsFields] = useState({
    nameError: false,
    descriptionError: false,
    fileError: false,
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const { name, description, file } = fields;

      if (!name || !description || !file.name) {
        return setErrorsFields({
          nameError: !name,
          descriptionError: !description,
          fileError: !file.name,
        });
      }
      return handleCreateDownload({ name, description, file });
    } catch (error) {
      toast.error('Erro ao criar o download');
    }
  };

  const handleSubmitEdit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const { name, description, file, status } = fields;

      if (!name || !description) {
        return setErrorsFields({
          nameError: !name,
          descriptionError: !description,
          fileError: !file.name,
        });
      }
      return handleEditDownload({ name, description, file, status });
    } catch (error) {
      toast.error('Erro ao editar o download');
    }
  };

  useEffect(() => {
    const fetchDownload = async () => {
      try {
        const data = await getDownload({
          id: editId as number,
        });

        setFields({
          name: data.name,
          description: data.description,
          file: {} as File,
          status: data.status,
        });
      } catch (error) {
        toast.error('Erro ao carregar o download');
      }
    };

    editId && fetchDownload();
  }, [editId]);

  return (
    <Modal
      onClickOutside={() => setIsOpen(false)}
      open={isOpen}
      title={editId ? 'Editar arquivo' : 'Cadastrar novo arquivo'}
    >
      <S.ModalContent onSubmit={editId ? handleSubmitEdit : handleSubmit}>
        <Input
          placeholder="Digite o nome do arquivo"
          onChange={(e) => setFields({ ...fields, name: e.target.value })}
          value={fields.name}
          onBlur={() => {
            if (!fields.name) {
              return setErrorsFields({
                ...errorsFields,
                nameError: true,
              });
            }
            setErrorsFields({
              ...errorsFields,
              nameError: false,
            });
          }}
          error={errorsFields.nameError}
        />
        <Textarea
          placeholder="Descrição"
          rows={6}
          onChange={(e) =>
            setFields({ ...fields, description: e.target.value })
          }
          maxLength={255}
          value={fields.description}
          onBlur={() => {
            if (!fields.description) {
              return setErrorsFields({
                ...errorsFields,
                descriptionError: true,
              });
            }
            setErrorsFields({
              ...errorsFields,
              descriptionError: false,
            });
          }}
          error={errorsFields.descriptionError}
        />
        <Dropzone
          setFile={(file) => setFields({ ...fields, file })}
          error={errorsFields.fileError}
        />
        <S.ButtonGroup>
          {editId ? (
            <Button
              label="Excluir"
              variant="danger"
              onClick={() => handleDeleteDownload(editId as number)}
              disabled={loadingDelete || loading}
              loading={loadingDelete}
            />
          ) : (
            <Button
              label="Cancelar"
              variant="outlined"
              onClick={() => setIsOpen(false)}
            />
          )}
          <Button
            label={editId ? 'Editar' : 'Cadastrar'}
            type="submit"
            disabled={loadingDelete || loading}
            loading={loading}
          />
        </S.ButtonGroup>
      </S.ModalContent>
    </Modal>
  );
};
