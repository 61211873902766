import styled from 'styled-components';
import { sg } from 'styles';

export const Overlay = styled.div<{ open: boolean }>`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);

  display: ${({ open }) => (open ? 'flex' : 'none')};
`;

export const Modal = styled.div<{ open: boolean }>`
  background: ${sg.colors.white};

  position: absolute;
  z-index: 101;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  max-width: 566px;
  width: 100%;

  display: ${({ open }) => (open ? 'unset' : 'none')};

  border-radius: 12px;
`;

export const CloseIcon = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;

  cursor: pointer;

  background: transparent;
  display: flex;
`;

export const Title = styled.h3`
  padding: 32px 32px 0px 32px;

  font-size: ${sg.fontSize.xlarge};
  color: ${sg.colors.textPrimary};
`;
