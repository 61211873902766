/* eslint-disable camelcase */
import { Button, Input, Modal, Textarea } from 'components';
import { useCourses } from 'pages/Courses/context';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getCourse } from 'services/api/course';

import * as S from './styles';

interface IModalCoursesProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const ModalCourses = ({
  isOpen,
  setIsOpen,
}: IModalCoursesProps): JSX.Element => {
  const {
    handleCreateCourses,
    editId,
    handleEditCourse,
    loading,
    handleDeleteCourse,
    loadingDelete,
  } = useCourses();

  const [fields, setFields] = useState({
    name: '',
    amount_hour: '',
    certificate: '',
    period: '',
    description: '',
    payment_url: '',
  });

  const [errorsFields, setErrorsFields] = useState({
    nameError: false,
    descriptionError: false,
    amount_hourError: false,
    certificateError: false,
    periodError: false,
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const {
        name,
        amount_hour,
        certificate,
        period,
        description,
        payment_url,
      } = fields;

      if (!name || !description || !amount_hour || !certificate || !period) {
        return setErrorsFields({
          nameError: !name,
          descriptionError: !description,
          amount_hourError: !amount_hour,
          certificateError: !certificate,
          periodError: !period,
        });
      }
      return editId
        ? handleEditCourse({
            name,
            amount_hour,
            certificate,
            period,
            description,
            payment_url,
          })
        : handleCreateCourses({
            name,
            amount_hour,
            certificate,
            period,
            description,
            payment_url,
          });
    } catch (error) {
      toast.error('Erro ao criar o curso');
    }
  };

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const data = await getCourse({
          id: editId as number,
        });

        setFields({
          name: data.name,
          description: data.description,
          amount_hour: String(data.amount_hour),
          certificate: data.certificate,
          period: data.period,
          payment_url: data.payment_url,
        });
      } catch (error) {
        toast.error('Erro ao carregar o curso');
      }
    };

    editId && fetchCourse();
  }, [editId]);

  return (
    <Modal
      onClickOutside={() => setIsOpen(false)}
      open={isOpen}
      title={editId ? 'Editar curso' : 'Cadastrar novo curso'}
    >
      <S.ModalContent onSubmit={handleSubmit}>
        <Input
          placeholder="Digite o nome do curso"
          onChange={(e) => setFields({ ...fields, name: e.target.value })}
          value={fields.name}
          onBlur={() => {
            if (!fields.name) {
              return setErrorsFields({
                ...errorsFields,
                nameError: true,
              });
            }
            setErrorsFields({
              ...errorsFields,
              nameError: false,
            });
          }}
          error={errorsFields.nameError}
        />

        <Input
          placeholder="Carga Horária"
          onChange={(e) =>
            setFields({ ...fields, amount_hour: e.target.value })
          }
          value={fields.amount_hour}
          onBlur={() => {
            if (!fields.amount_hour) {
              return setErrorsFields({
                ...errorsFields,
                amount_hourError: true,
              });
            }
            setErrorsFields({
              ...errorsFields,
              amount_hourError: false,
            });
          }}
          error={errorsFields.amount_hourError}
        />

        <Input
          placeholder="Certificação"
          onChange={(e) =>
            setFields({ ...fields, certificate: e.target.value })
          }
          value={fields.certificate}
          onBlur={() => {
            if (!fields.certificate) {
              return setErrorsFields({
                ...errorsFields,
                certificateError: true,
              });
            }
            setErrorsFields({
              ...errorsFields,
              certificateError: false,
            });
          }}
          error={errorsFields.certificateError}
        />

        <Input
          placeholder="Período para realização do curso"
          onChange={(e) => setFields({ ...fields, period: e.target.value })}
          value={fields.period}
          onBlur={() => {
            if (!fields.period) {
              return setErrorsFields({
                ...errorsFields,
                periodError: true,
              });
            }
            setErrorsFields({
              ...errorsFields,
              periodError: false,
            });
          }}
          error={errorsFields.periodError}
        />

        <Input
          placeholder="URL para pagamento"
          onChange={(e) =>
            setFields({ ...fields, payment_url: e.target.value })
          }
          value={fields.payment_url}
        />

        <Textarea
          placeholder="Descrição"
          rows={6}
          onChange={(e) =>
            setFields({ ...fields, description: e.target.value })
          }
          value={fields.description}
          onBlur={() => {
            if (!fields.description) {
              return setErrorsFields({
                ...errorsFields,
                descriptionError: true,
              });
            }
            setErrorsFields({
              ...errorsFields,
              descriptionError: false,
            });
          }}
          error={errorsFields.descriptionError}
        />
        <S.ButtonGroup>
          {editId ? (
            <Button
              label="Excluir"
              variant="danger"
              onClick={() => handleDeleteCourse(editId as number)}
              disabled={loadingDelete || loading}
              loading={loadingDelete}
            />
          ) : (
            <Button
              label="Cancelar"
              variant="outlined"
              onClick={() => setIsOpen(false)}
            />
          )}
          <Button
            label={editId ? 'Editar' : 'Cadastrar'}
            type="submit"
            disabled={loadingDelete || loading}
            loading={loadingDelete}
          />
        </S.ButtonGroup>
      </S.ModalContent>
    </Modal>
  );
};
