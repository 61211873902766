import styled from 'styled-components';
import { sg } from 'styles';

export const ButtonIcon = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  color: ${sg.colors.quaternary};
  background: transparent;
  font-size: ${sg.fontSize.small};
  font-family: ${sg.fontFamily.primary};
  font-weight: 500;

  & + button {
    margin-left: 8px;
  }

  svg {
    margin-right: 8px;
  }
`;
