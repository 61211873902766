import {
  ICreateDownload,
  IDeleteDownload,
  IEditDownload,
  IGetDownload,
  IGetDownloads,
} from '.';
import { api } from '..';

export const getDownloads: IGetDownloads = async (params) => {
  const { data } = await api.get('/downloads', {
    params,
  });

  return {
    page: data.page,
    perPage: data.perPage,
    total: data.total,
    lastPage: data.lastPage,
    data: data.data,
  };
};

export const createDownload: ICreateDownload = async (params) => {
  const formData = new FormData();

  formData.append('name', params.name);
  formData.append('description', params.description);
  formData.append('file', params.file);

  const { status } = await api.post('/download', formData);

  return { status };
};

export const editDownload: IEditDownload = async (params) => {
  const formData = new FormData();

  params.name && formData.append('name', params.name);
  params.description && formData.append('description', params.description);
  params.file?.name && formData.append('file', params.file);
  formData.append('status', params.status ? 'true' : 'false');

  const { status } = await api.put(`/download/${params.id}`, formData);

  return { status };
};

export const getDownload: IGetDownload = async (params) => {
  const { data } = await api.get(`/download/${params.id}`);

  return data;
};

export const deleteDownload: IDeleteDownload = async (params) => {
  const { status } = await api.delete(`/download/${params.id}`);

  return { status };
};
