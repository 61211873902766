import React, { useState, createContext, useContext } from 'react';
import { toast } from 'react-toastify';
import {
  createCourse,
  deleteCourse,
  editCourse,
  getCourses,
} from 'services/api/course';
import {
  ICoursesResponseProps,
  ICreateCourseProps,
  IEditCourseProps,
} from 'types/CourseTypes';

type ICoursesProps = {
  children: React.ReactNode;
};

export type ICoursesContextProps = {
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  perPage: number;
  totalRegisters: number;
  courses: ICoursesResponseProps[];
  setEditId: React.Dispatch<React.SetStateAction<number | null>>;
  editId: number | null;
  handleGetCourses: ({ page }: { page?: number }) => void;
  handleCreateCourses: (data: ICreateCourseProps) => void;
  handleEditCourse: (params: Omit<IEditCourseProps, 'id'>) => void;
  handleEditStatus: (id: number, status: boolean) => void;
  handleDeleteCourse: (id: number) => void;
  loading: boolean;
  loadingDelete: boolean;
};

export const CoursesContext = createContext<ICoursesContextProps>(
  {} as ICoursesContextProps
);

export const CoursesProvider = ({ children }: ICoursesProps): JSX.Element => {
  const perPage = 8;
  const [editId, setEditId] = useState<number | null>(null);

  const [page, setPage] = useState(1);
  const [totalRegisters, setTotalRegisters] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [courses, setCourses] = useState<ICoursesResponseProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const handleGetCourses = async ({ page = 1 }: { page?: number }) => {
    try {
      const { data, total } = await getCourses({
        page,
        perPage,
      });

      setCourses(data);
      setTotalRegisters(total);
    } catch (error) {
      toast.error('Erro ao carregar os cursos');
    }
  };

  const handleCreateCourses = async (course: ICreateCourseProps) => {
    try {
      setLoading(true);
      const { status } = await createCourse(course);

      if (status === 201) {
        toast.success('Download criado com sucesso');
        setIsOpenModal(false);
        handleGetCourses({ page: 1 });
      }
    } catch (error) {
      toast.error('Erro ao criar o curso');
    } finally {
      setLoading(false);
    }
  };

  const handleEditCourse = async (params: Omit<IEditCourseProps, 'id'>) => {
    try {
      setLoading(true);

      const { status } = await editCourse({ ...params, id: editId as number });

      if (status === 200) {
        toast.success('Curso editado com sucesso');
        setIsOpenModal(false);
        handleGetCourses({ page: 1 });
      }
    } catch (error) {
      toast.error('Erro ao editar o curso');
    } finally {
      setLoading(false);
    }
  };

  const handleEditStatus = async (id: number, status: boolean) => {
    try {
      console.log(status);
      const { status: statusCode } = await editCourse({ id, status });
      if (statusCode === 200) {
        toast.success('Status atualizado com sucesso');
        handleGetCourses({});
      }
    } catch (error) {
      toast.error('Erro ao alterar o status');
    }
  };

  const handleDeleteCourse = async (id: number) => {
    try {
      setLoadingDelete(true);
      const { status } = await deleteCourse({ id });

      if (status === 200) {
        toast.success('Curso deletado com sucesso');
        handleGetCourses({ page: 1 });
        setIsOpenModal(false);
      }
    } catch (error) {
      toast.error('Erro ao deletar curso');
    } finally {
      setLoadingDelete(false);
    }
  };

  return (
    <CoursesContext.Provider
      value={{
        isOpenModal,
        setIsOpenModal,
        totalRegisters,
        page,
        perPage,
        setPage,
        handleGetCourses,
        courses,
        handleCreateCourses,
        editId,
        setEditId,
        handleEditStatus,
        handleEditCourse,
        loading,
        handleDeleteCourse,
        loadingDelete,
      }}
    >
      {children}
    </CoursesContext.Provider>
  );
};

export const useCourses = () => {
  const context = useContext(CoursesContext);

  if (!context) {
    throw new Error('You just can access this context inside a provider');
  }

  return context;
};
