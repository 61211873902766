import { sg } from 'styles';
import styled from 'styled-components';

export const Container = styled.div<{ error: boolean }>`
  margin-top: 16px;
  cursor: pointer;
  .dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed
      ${({ error }) => (error ? sg.colors.red2 : sg.colors.textSecondary)};
    height: 150px;
    padding: 10px;
    background: ${sg.colors.background};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h4 {
    font-size: ${sg.fontSize.default};
    color: ${sg.colors.grey5};
    margin: 6px 0px;
  }
  p {
    font-size: ${sg.fontSize.small};
    color: ${sg.colors.grey7};
    text-align: center;
  }

  button {
    margin-top: 16px;

    background: ${sg.colors.tertiary};
    color: ${sg.colors.white};
    height: 40px;

    padding: 0px 24px;
    border-radius: 5px;
  }
`;

export const Filename = styled.h4`
  font-size: ${sg.fontSize.default};
  color: ${sg.colors.grey5};
`;
