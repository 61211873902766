import styled from 'styled-components';
import { sg } from 'styles/style-guide';

import backgroundImg from 'assets/background-login.png';

export const Container = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
`;

export const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;
  width: 100%;
  max-width: 576px;

  margin: 0 88px;

  ${sg.media.lessThan(1300)} {
    max-width: 100%;
    margin: 0 24px;
  }
`;

export const Logo = styled.img`
  ${sg.media.greaterThan(1300)} {
    display: none;
  }

  width: 100%;
  max-width: 280px;

  align-self: center;
  margin-bottom: 50px;
`;

export const Title = styled.h2`
  font-size: ${sg.fontSize.titleSmall};
  margin-bottom: 8px;
`;

export const Description = styled.span`
  margin-bottom: 32px;
  font-size: ${sg.fontSize.medium};
  color: ${sg.colors.textSecondary};
`;

export const Form = styled.form`
  button {
    margin-top: 16px;
  }
`;

export const ContentRight = styled.div`
  flex: 1;

  background-image: url(${backgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  padding: 44px;

  img {
    width: 200px;
  }

  ${sg.media.lessThan(1300)} {
    display: none;
  }
`;
