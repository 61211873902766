import { Button, Modal } from 'components';
import { useContacts } from 'pages/Contacts/context';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getContact } from 'services/api/contact';
import { IContactProps } from 'types/ContactTypes';

import * as S from './styles';

interface IModalContactsProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const ModalContacts = ({
  isOpen,
  setIsOpen,
}: IModalContactsProps): JSX.Element => {
  const { idContact, handleStatusContact } = useContacts();
  const [contact, setContact] = useState<IContactProps | null>(null);

  useEffect(() => {
    const fetchContact = async () => {
      try {
        const data = await getContact({ id: idContact as number });
        setContact(data);
      } catch (error) {
        toast.error('Erro ao carregar contato');
      }
    };

    fetchContact();
  }, [idContact]);

  return (
    <Modal
      onClickOutside={() => setIsOpen(false)}
      open={isOpen}
      title="Detalhes do contato"
    >
      <S.ModalContent>
        <S.Input>
          <S.Label htmlFor="name">Nome completo</S.Label>
          <S.InputText id="name">{contact?.name}</S.InputText>
        </S.Input>

        <S.Input>
          <S.Label htmlFor="email">Email para contato</S.Label>
          <S.InputText id="email">{contact?.email}</S.InputText>
        </S.Input>

        <S.Input>
          <S.Label htmlFor="description">Descrição</S.Label>
          <S.InputText id="description">{contact?.description}</S.InputText>
        </S.Input>
        <S.ButtonGroup>
          <Button
            label="Cancelar"
            variant="outlined"
            onClick={() => setIsOpen(false)}
          />

          <Button
            label="Marcar como realizado"
            disabled={contact?.status}
            onClick={() =>
              handleStatusContact({
                status: !contact?.status,
              })
            }
          />
        </S.ButtonGroup>
      </S.ModalContent>
    </Modal>
  );
};
