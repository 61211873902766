import React from 'react';

import * as S from './styles';

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
}

export const Input = ({ error = false, ...rest }: IInputProps) => {
  return <S.Container {...rest} error={error} />;
};
