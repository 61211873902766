import React from 'react';

import * as S from './styles';

interface ITextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: boolean;
}

export const Textarea = ({ error = false, ...rest }: ITextareaProps) => {
  return <S.Container {...rest} error={error} />;
};
