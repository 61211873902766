/* eslint-disable prefer-destructuring */
import {
  IGetContactsProps,
  IGetContactsResponseProps,
  IContactProps,
  IGetContactProps,
} from 'types/ContactTypes';
import * as integration from './integration';

export const getContacts: IGetContacts = integration.getContacts;
export const getContact: IGetContact = integration.getContact;
export const updateStatus: IUpdateStatus = integration.updateStatus;

export type IGetContacts = (
  params: IGetContactsProps
) => Promise<IGetContactsResponseProps>;

export type IGetContact = (params: IGetContactProps) => Promise<IContactProps>;

export type IUpdateStatus = (params: {
  id: number;
  status: boolean;
}) => Promise<{ status: number }>;
