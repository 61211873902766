/* eslint-disable prefer-destructuring */
import {
  ICreateDownloadsProps,
  IDeleteDownloadProps,
  IDownloadResponseProps,
  IEditDownloadsProps,
  IGetDownloadProps,
  IGetDownloadsProps,
  IGetDownloadsResponseProps,
} from 'types/DownloadTypes';
import * as integration from './integration';

export const getDownloads: IGetDownloads = integration.getDownloads;
export const createDownload: ICreateDownload = integration.createDownload;
export const editDownload: IEditDownload = integration.editDownload;
export const getDownload: IGetDownload = integration.getDownload;
export const deleteDownload: IDeleteDownload = integration.deleteDownload;

export type IGetDownloads = (
  params: IGetDownloadsProps
) => Promise<IGetDownloadsResponseProps>;

export type ICreateDownload = (
  params: ICreateDownloadsProps
) => Promise<{ status: number }>;

export type IEditDownload = (
  params: IEditDownloadsProps
) => Promise<{ status: number }>;

export type IGetDownload = (
  params: IGetDownloadProps
) => Promise<IDownloadResponseProps>;

export type IDeleteDownload = (
  params: IDeleteDownloadProps
) => Promise<{ status: number }>;
