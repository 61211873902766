import React from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { sg } from 'styles';

import * as S from './styles';

export interface ModalProps {
  open: boolean;
  onClickOutside: () => void;
  onClickClose?: () => void;
  closeOnOverlay?: boolean;
  children: React.ReactNode;
  title: string;
}

export const Modal = ({
  open,
  onClickOutside,
  onClickClose,
  closeOnOverlay = true,
  children,
  title,
}: ModalProps): JSX.Element => {
  const handleCloseButton = () => {
    if (onClickClose) return onClickClose();
    return onClickOutside();
  };

  return (
    <>
      <S.Overlay
        open={open}
        onClick={() => closeOnOverlay && onClickOutside()}
      />
      <S.Modal open={open}>
        <S.Title>{title}</S.Title>
        <S.CloseIcon onClick={handleCloseButton}>
          <MdOutlineClose size={24} color={sg.colors.grey4} />
        </S.CloseIcon>
        {children}
      </S.Modal>
    </>
  );
};
