import styled from 'styled-components';

export const ButtonIcon = styled.button`
  background: transparent;
  font-size: 0;

  & + button {
    margin-left: 8px;
  }
`;
