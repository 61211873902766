import styled from 'styled-components';
import { sg } from 'styles';

export const Container = styled.div`
  background: ${sg.colors.white};
  border-radius: 12px;

  margin: 32px;
  padding: 32px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.h3`
  color: ${sg.colors.grey5};
  font-size: ${sg.fontSize.xlarge};
`;

export const Content = styled.div`
  margin-top: 32px;
`;
